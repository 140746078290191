.modal {
    background-color: rgba(#000, 0.5);

    button.close {
        background: transparent;
        border: none;
        opacity: 0.5;

        font-size: 1.5rem;
        font-weight: 700;
        line-height: 1;
        color: #000;
        text-shadow: 0 1px 0 #fff;
    }
}
