.navbar {
    background-color: $dark-gray;
    color: white;
    padding: 15px 20px 0 0;
    align-items: normal;
}

.logout {
    height: 100%;
    width:100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    background-color: $dark-gray;
    padding-top: 5px;

    a, & {
        color: white;
    }

    a {
        display: block;
        text-decoration: none;
    }

    svg {
        width: 28px;
        margin-right: 7px;
        object-position: left;

        path {
            fill: white;
        }
    }
}

.bottom-line {
    display: flex;

    .color-bar {
        height: 5px;
        flex: 1;

        &:nth-child(1) {
            background-color: $blue;
        }
        &:nth-child(2) {
            background-color: $green;
        }
        &:nth-child(3) {
            background-color: $orange;
        }
    }
}

.navbar-brand {
    padding: 0;
}

body.fullsize {
    .navbar {
        padding-left: 262px;
    }

    .container {
        display: flex !important;
        padding: 0;
        min-width: 100%;
    }

    @media only screen and (max-width: 900px) and (min-width: 601px) {
        .navbar {
            padding-left: 100px;
            margin: 0 auto;
        }
    }

    @media only screen and (max-width: 750px) {
        .navbar {
            padding-left: 20px;
            margin: 0 auto;
        }

        .logout {
            display:none;
        }
        
        .container {
            display: block !important
        }
    }
}
