@import '~bootstrap/scss/bootstrap';
@import "font";
@import 'variables';
@import "font-styles";
@import "window";
@import "table";
@import "navbar";
@import "menu";
@import "tabs";
@import "containers";
@import "form";
@import "login";
@import "registration";
@import "modal";
@import "search";

html, body {
    min-width:380px;
    height: 100%;
}

@for $i from 1 through 5 {
    .p-#{$i} {
        padding: #{$i * 10}px !important;
    }
}

