.sidebar {
    height: 100%;
    min-width: 262px;

    background-color: $dark-gray;
    padding-top: 40px;

    a, & {
        color: white;
    }

    a {
        text-decoration: none;
    }

    button.burger {
        margin-left: 20px;
    }

    h2, ul {
        padding-left: 20px;
    }

    h2 {
        font-size: 20px;

        border-bottom: solid 1px white;
        margin-bottom: 10px;
    }

    ul {
        list-style: none;

        li {
            margin-bottom: 20px;
        }

        a {
            display: block;
        }

        svg {
            width: 28px;
            margin-right: 7px;
            object-position: left;

            path {
                fill: white;
            }
        }
    }
}

button.burger {
    background-color: transparent;
    border: none;
    margin-bottom: 30px;

    span.bar {
        display: block;
        width: 30px;
        height: 4px;
        margin: 6px 0;
        background-color: white;

        &:nth-child(2) {
            width: 20px;
        }
    }
}

.logout_menu {
    display:none;
}

@media only screen and (max-width: 750px) {
    .sidebar {
        padding-top: 10px;
        min-width: 100%;
        max-height: 100%;
        transition: max-height 350ms ease;
    }

    .logout_menu {
        display:block;
    }
}

body.menu-close {

    .sidebar {
        min-width: 70px;
        transition: min-width 350ms ease;
    }

    @media only screen and (max-width: 750px) {
        .sidebar {
            min-width: 100%;
            max-height: 60px;
            transition: max-height 350ms ease;
        }
    }

    h2.title_menu {
        display: none;
    }

    ul.menu {
        span {
            display: none;
        }
    }
}

