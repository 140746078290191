.nav-tabs {
    border-bottom: none;
    margin-bottom: 60px;

    .nav-link {
        border: none;
        background-color: $light-gray;
        color: $dark-gray;
        padding: 26px 22px;
        border-radius: 0;
        line-height: 19px;
        position: relative;

        &:after {
            display: block;
            content: " ";
            position: absolute;
            right: -3px;
            height: 100%;
            width: 3px;
            background: linear-gradient(to right, #00000029, transparent);
            top: 0;
            z-index: 1;
        }

        &.active {
            color: $dark-gray;
        }

        &:hover, &:active, &:focus {
            isolation: auto;
        }
    }

    @media only screen and (max-width: 750px) {
        .nav-link {
            padding: 18px 10px;
            width:25%;
            font-size:12px;
        }
    }
}

.tab-content {
    padding: 0 20px;
}


