$white: #FFF;
$light-gray: #E5E5E5;
$gray: #888888;
$dark-gray: #2F3229;
$blue: #0097BD;
$blue-light: #0b5ed7;
$dark-blue: #0000FF;
$green: #298729;
$orange: #F25100;

