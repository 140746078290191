label, .label {
    display: flex;
    justify-content: center;
    height: 30px;
    margin-bottom: 10px;
    word-wrap: break-word;

    span {
        display: block;
        width: 50%;
        text-align: right;
        padding-right: 25px;
        color: $gray;
        align-self: center;

        &.value {
            padding: 0;
            text-align: left;
        }
    }

    span.unique {
        width: 30%;
    }

    input {
        width: 50%;
        border: 0.5px solid $gray;
    }

    select {
        width: 50%;
        border: 0.5px solid $gray;
    }

    @media only screen and (max-width: 750px) {
        span { 
            display: inline-block;
            width: 30%;
            text-align: right;
            padding-right: 15px;
            color: $gray;
            align-self: center;
            font-size: 13px;

            &.value {
                padding: 0;
                text-align: left;
            }
        }

        input {
            font-size: 13px;
            width: 70%;
        }

        select {
            width: 70%;
        }
    }

    &.checkbox {
        justify-content: start;

        input[type=checkbox] {
            display: none;
        }

        div.box {
            display: block;
            content: " ";
            width: 20px;
            height: 20px;
            background-color: $white;
            border: 1px solid $gray;
            align-self: center;
            line-height: 20px;
            text-align: center;
        }

        input[type=checkbox]:checked ~ div.box::before {
            content: '✔';
            // content: '\f00c'; // arrow up code ✔
            // background-color: $dark-gray;
        }
    }
}

.btn-primary {
    background-color: $dark-gray;
    width: 50%;
    border-radius: 0;
    border: none;
    position: relative;
    // color: $light-gray;

    svg {
        max-width: 15px;
        max-height: 15px;
        margin-right: 5px;

        line {
            stroke: $white;
        }
    }
}

.btn-language {
    width: 100px; 
    position:absolute;
    right: 20px;
    z-index:2;
}

.user-picture {
    position:absolute; 
    top: 0;
}

@media only screen and (max-width: 750px) {

    .btn-primary {
        width: 70%;
    }

    .user-picture {
        right: 0px; 
        top: -100px;
    }

    .btn-language {
        width: 100px; 
        position:absolute;
        right: 10px;
        top: 105px;
        background-color: $light-gray;
        color: $dark-gray;
        z-index:1;
    }
}
