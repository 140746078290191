form.login {
    max-width: 290px;
    padding-top: 90px;
    padding-bottom: 150px;
    margin: 0 auto;

    input {
        margin-bottom: 15px;
        border-radius: 0;
        border: solid 2px $dark-gray;
    }

    h1 {
        margin-bottom: 50px;
        word-wrap: break-word;
    }
}

.btn-login {
    display: block;
    background-color: $dark-gray;
    border-radius: 0;
    border: none;
    text-align: center;
    padding: 18px 0 14px 0;
    margin: 0 0 20px;
    color: $white;
    width: 100%;
}

label.checkbox {
    height: 80px;
}

.checkbox span {
    display: block;
    width: 50%;
    text-align: right;
    padding-right: 45px;
    color: $gray;
    align-self: center;
}

