body {
    font-family: "MetaWeb", "sans-serif";
    font-size: 16px;
    color: $dark-gray;
}

a {
    color: $dark-blue;
}

h2 {
    font-size: 27px;
    border-bottom: 1px solid $dark-gray;
    padding-bottom: 10px;
    margin-bottom: 15px;
}
