.window-wrapper {
    width: 100%;
    min-height: 100vh;
    background-color: $light-gray;
    display: flex;
    align-items: center;
    justify-content: center;
}

.window {
    position:relative;
    width: 895px;
    background-color: white;
    box-shadow: 8px 8px 0 #0000001A;
    border: 2px solid $dark-gray;

    .navbar {
        padding-left: 100px;
    }

    @media only screen and (max-width: 750px) {
        .navbar {
            padding-left: 20px;
            margin: 0 auto;
        }
    }
}


