$font-dir: '../fonts/meta/';

@font-face {
    font-family: 'MetaScWeb';
    src:  url($font-dir + 'MetaScWeb-Bold.eot');
    src:  url($font-dir + 'MetaScWeb-Bold.eot?#iefix') format('embedded-opentype'),
     url($font-dir + 'MetaScWeb-Bold.woff') format('woff'),
     url($font-dir + 'MetaScWeb-Bold.woff2') format('woff2'),
     url($font-dir + 'MetaScWeb-Bold.ttf') format('truetype'),
     url($font-dir + 'MetaScWeb-Bold.svg#MetaScWeb-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'MetaScWeb';
    src:  url($font-dir + 'MetaScWeb-BoldIta.eot');
    src:  url($font-dir + 'MetaScWeb-BoldIta.eot?#iefix') format('embedded-opentype'),
     url($font-dir + 'MetaScWeb-BoldIta.woff') format('woff'),
     url($font-dir + 'MetaScWeb-BoldIta.woff2') format('woff2'),
     url($font-dir + 'MetaScWeb-BoldIta.ttf') format('truetype'),
     url($font-dir + 'MetaScWeb-BoldIta.svg#MetaScWeb-BoldIta') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'MetaScWeb-Book';
    src:  url($font-dir + 'MetaScWeb-Book.eot');
    src:  url($font-dir + 'MetaScWeb-Book.eot?#iefix') format('embedded-opentype'),
     url($font-dir + 'MetaScWeb-Book.woff') format('woff'),
     url($font-dir + 'MetaScWeb-Book.woff2') format('woff2'),
     url($font-dir + 'MetaScWeb-Book.ttf') format('truetype'),
     url($font-dir + 'MetaScWeb-Book.svg#MetaScWeb-Book') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'MetaScWeb';
    src:  url($font-dir + 'MetaScWeb-BookIta.eot');
    src:  url($font-dir + 'MetaScWeb-BookIta.eot?#iefix') format('embedded-opentype'),
     url($font-dir + 'MetaScWeb-BookIta.woff') format('woff'),
     url($font-dir + 'MetaScWeb-BookIta.woff2') format('woff2'),
     url($font-dir + 'MetaScWeb-BookIta.ttf') format('truetype'),
     url($font-dir + 'MetaScWeb-BookIta.svg#MetaScWeb-BookIta') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'MetaScWeb';
    src:  url($font-dir + 'MetaScWeb-Medium.eot');
    src:  url($font-dir + 'MetaScWeb-Medium.eot?#iefix') format('embedded-opentype'),
     url($font-dir + 'MetaScWeb-Medium.woff') format('woff'),
     url($font-dir + 'MetaScWeb-Medium.woff2') format('woff2'),
     url($font-dir + 'MetaScWeb-Medium.ttf') format('truetype'),
     url($font-dir + 'MetaScWeb-Medium.svg#MetaScWeb-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'MetaScWeb';
    src:  url($font-dir + 'MetaScWeb-MediumIta.eot');
    src:  url($font-dir + 'MetaScWeb-MediumIta.eot?#iefix') format('embedded-opentype'),
     url($font-dir + 'MetaScWeb-MediumIta.woff') format('woff'),
     url($font-dir + 'MetaScWeb-MediumIta.woff2') format('woff2'),
     url($font-dir + 'MetaScWeb-MediumIta.ttf') format('truetype'),
     url($font-dir + 'MetaScWeb-MediumIta.svg#MetaScWeb-MediumIta') format('svg');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'MetaScWeb';
    src:  url($font-dir + 'MetaScWeb-Normal.eot');
    src:  url($font-dir + 'MetaScWeb-Normal.eot?#iefix') format('embedded-opentype'),
     url($font-dir + 'MetaScWeb-Normal.woff') format('woff'),
     url($font-dir + 'MetaScWeb-Normal.woff2') format('woff2'),
     url($font-dir + 'MetaScWeb-Normal.ttf') format('truetype'),
     url($font-dir + 'MetaScWeb-Normal.svg#MetaScWeb-Normal') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'MetaScWeb';
    src:  url($font-dir + 'MetaScWeb-NormalIta.eot');
    src:  url($font-dir + 'MetaScWeb-NormalIta.eot?#iefix') format('embedded-opentype'),
     url($font-dir + 'MetaScWeb-NormalIta.woff') format('woff'),
     url($font-dir + 'MetaScWeb-NormalIta.woff2') format('woff2'),
     url($font-dir + 'MetaScWeb-NormalIta.ttf') format('truetype'),
     url($font-dir + 'MetaScWeb-NormalIta.svg#MetaScWeb-NormalIta') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'MetaWeb';
    src:  url($font-dir + 'MetaWeb-Bold.eot');
    src:  url($font-dir + 'MetaWeb-Bold.eot?#iefix') format('embedded-opentype'),
     url($font-dir + 'MetaWeb-Bold.woff') format('woff'),
     url($font-dir + 'MetaWeb-Bold.woff2') format('woff2'),
     url($font-dir + 'MetaWeb-Bold.ttf') format('truetype'),
     url($font-dir + 'MetaWeb-Bold.svg#MetaWeb-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'MetaWeb';
    src:  url($font-dir + 'MetaWeb-BoldIta.eot');
    src:  url($font-dir + 'MetaWeb-BoldIta.eot?#iefix') format('embedded-opentype'),
     url($font-dir + 'MetaWeb-BoldIta.woff') format('woff'),
     url($font-dir + 'MetaWeb-BoldIta.woff2') format('woff2'),
     url($font-dir + 'MetaWeb-BoldIta.ttf') format('truetype'),
     url($font-dir + 'MetaWeb-BoldIta.svg#MetaWeb-BoldIta') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'MetaWeb-Book';
    src:  url($font-dir + 'MetaWeb-Book.eot');
    src:  url($font-dir + 'MetaWeb-Book.eot?#iefix') format('embedded-opentype'),
     url($font-dir + 'MetaWeb-Book.woff') format('woff'),
     url($font-dir + 'MetaWeb-Book.woff2') format('woff2'),
     url($font-dir + 'MetaWeb-Book.ttf') format('truetype'),
     url($font-dir + 'MetaWeb-Book.svg#MetaWeb-Book') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'MetaWeb';
    src:  url($font-dir + 'MetaWeb-BookIta.eot');
    src:  url($font-dir + 'MetaWeb-BookIta.eot?#iefix') format('embedded-opentype'),
     url($font-dir + 'MetaWeb-BookIta.woff') format('woff'),
     url($font-dir + 'MetaWeb-BookIta.woff2') format('woff2'),
     url($font-dir + 'MetaWeb-BookIta.ttf') format('truetype'),
     url($font-dir + 'MetaWeb-BookIta.svg#MetaWeb-BookIta') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'MetaWeb';
    src:  url($font-dir + 'MetaWeb-Medium.eot');
    src:  url($font-dir + 'MetaWeb-Medium.eot?#iefix') format('embedded-opentype'),
     url($font-dir + 'MetaWeb-Medium.woff') format('woff'),
     url($font-dir + 'MetaWeb-Medium.woff2') format('woff2'),
     url($font-dir + 'MetaWeb-Medium.ttf') format('truetype'),
     url($font-dir + 'MetaWeb-Medium.svg#MetaWeb-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'MetaWeb';
    src:  url($font-dir + 'MetaWeb-MediumIta.eot');
    src:  url($font-dir + 'MetaWeb-MediumIta.eot?#iefix') format('embedded-opentype'),
     url($font-dir + 'MetaWeb-MediumIta.woff') format('woff'),
     url($font-dir + 'MetaWeb-MediumIta.woff2') format('woff2'),
     url($font-dir + 'MetaWeb-MediumIta.ttf') format('truetype'),
     url($font-dir + 'MetaWeb-MediumIta.svg#MetaWeb-MediumIta') format('svg');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'MetaWeb';
    src:  url($font-dir + 'MetaWeb-Normal.eot');
    src:  url($font-dir + 'MetaWeb-Normal.eot?#iefix') format('embedded-opentype'),
     url($font-dir + 'MetaWeb-Normal.woff') format('woff'),
     url($font-dir + 'MetaWeb-Normal.woff2') format('woff2'),
     url($font-dir + 'MetaWeb-Normal.ttf') format('truetype'),
     url($font-dir + 'MetaWeb-Normal.svg#MetaWeb-Normal') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'MetaWeb';
    src:  url($font-dir + 'MetaWeb-NormalIta.eot');
    src:  url($font-dir + 'MetaWeb-NormalIta.eot?#iefix') format('embedded-opentype'),
     url($font-dir + 'MetaWeb-NormalIta.woff') format('woff'),
     url($font-dir + 'MetaWeb-NormalIta.woff2') format('woff2'),
     url($font-dir + 'MetaWeb-NormalIta.ttf') format('truetype'),
     url($font-dir + 'MetaWeb-NormalIta.svg#MetaWeb-NormalIta') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}
