.table-striped {
    thead {
        background-color: $light-gray;
        // font-family: 'Roboto', sans-serif;
        font-size: 16px;

        tr {
            th {
                color: $dark-gray;

                a {
                    color: $dark-gray;
                }
            }
        }
    }

    tbody {
        // font-family: 'Open Sans', sans-serif;
        font-size: 16px;

        [data-href] {
            cursor: pointer;
        }

        tr{
            background-color: $light-gray;
            &:hover{
                background-color: $blue !important;
            }
            &:nth-of-type(2n+1) {
                background-color: $light-gray;
            }

            .no-redirect {
                // background-color: white;
                text-align:center;
            }

            td.invoice-run-row {
                cursor: pointer;
            }

            td {
                a{
                    color: $dark-gray;
                }
            }
        }
    }
}

.borderless {
    border: none !important;
}

nav {
    .pagination {
        .page-item {
            margin: 0.15rem;

            .page-link {
                color: $gray;
                border-radius: 0.25rem;
            }

            &.active span {
                background-color: $gray;
                border-color: $gray;
                color: $light-gray;
            }
        }
    }
}

.actions a.btn-primary, .actions button.btn-primary {
    color: $light-gray;
    float:left;
    margin:5px;
    width:100px;

    svg {
        width: 28px;

        path {
            fill: $white;
        }
    }
}
