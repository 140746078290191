.content-box {
    background-color: $light-gray;
    padding: 30px;

    .col-sm {
        position:relative;
    }
    
    @media only screen and (max-width: 1024px) {

        .col-sm {
            flex: 100%;
        }
    }
}

.info-box {
    border: 1px solid $light-gray;
    border-radius: 5px;
    padding: 5px 20px;
    margin: 20px 0px 20px 0px;

    svg {
        max-width: 15px;
        max-height: 15px;
        margin-right: 5px;

        line, path {
            stroke: $dark-gray;
        }
    }
}
