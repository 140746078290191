form.registration {
    max-width: 280px;
    padding-top: 90px;
    padding-bottom: 150px;
    margin: 0 auto;

    input {
        margin-bottom: 15px;
        border-radius: 0;
        border: solid 2px $dark-gray;
    }

    h1 {
        margin-bottom: 50px;
    }
}

.btn-agb {
    display: block;
    background-color: $dark-gray;
    border-radius: 0;
    border: none;
    text-align: center;
    padding: 18px 0 14px 0;
    margin: 0 0 20px;
    color: $white;
    width: 100%;
}

.btn-login:hover, .btn-agb:hover {
    color: $white;
    background-color: $blue-light;
    border-color: $blue-light;
}

textarea#privacyTerms {
    resize: none;
    font-size:12px;
    height: 100px;
}

label.checkboxRegister {
    height: 80px;
}

.checkboxRegister span {
    display: block;
    width: 100%;
    text-align: right;
    padding-right: 45px;
    color: $gray;
    align-self: center;
}

.checkboxRegister div.box.boxActive {
    border: 2px solid $dark-gray;
}

.terms_title {
    display:block; 
    text-align:center;
    font-weight: bold;
}

.terms_link {
    color: black;
    text-decoration: none; /* no underline */
}

//tooltip checkbox

// *[data-tooltip] {
//     position: relative;
// }

// *[data-tooltip]:not(.boxActive)::after {
//     content: attr(data-tooltip);

//     position: absolute;
//     top: -20px;
//     left: 0px;
//     width: 150px;

//     pointer-events: none;
//     opacity: 0;
//     -webkit-transition: opacity .15s ease-in-out;
//     -moz-transition: opacity .15s ease-in-out;
//     -ms-transition: opacity .15s ease-in-out;
//     -o-transition: opacity .15s ease-in-out;
//     transition: opacity .15s ease-in-out;

//     display: block;
//     font-size: 12px;
//     line-height: 16px;
//     background: #fefdcd;
//     padding: 2px 2px;
//     border: 1px solid #c0c0c0;
//     box-shadow: 2px 4px 5px rgba(0, 0, 0, 0.4);
// }

// *[data-tooltip]:hover::after {
//     opacity: 1;
// }